@import url("https://fonts.googleapis.com/css?family=Varela+Round");
@import url("https://fonts.googleapis.com/css?family=Caveat+Brush");

body {
  background-color: #222 !important;
  margin: 0 30px 0 0;
  font-family: "Varela Round", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.5px;
  padding-bottom: 100px;
}

.css-zr3fa5[disabled],
.css-zr3fa5[aria-disabled="true"],
.css-zr3fa5[data-disabled],
.css-n1k2ji[disabled],
.css-n1k2ji[aria-disabled="true"],
.css-n1k2ji[data-disabled],
.css-1lojvv0[disabled],
.css-1lojvv0[aria-disabled="true"],
.css-1lojvv0[data-disabled] {
  cursor: default !important;
}

ul[role="listbox"] {
  z-index: 1 !important;
  position: absolute !important;
  width: 75% !important;
  background: #3a434d;
}

ul[role="listbox"] li {
  background: #3a434d;
  cursor: pointer;
}

ul[role="listbox"] li:hover,
ul[role="listbox"] li:active {
  background: #4e5d6e;
}

#tagField .chakra-form__label {
  margin-bottom: -6px !important;
}

#tagField .chakra-button {
  background: #3a434d;
}

/* #tagField .chakra-stack.css-nq00bg {
  margin-bottom: 5px;
} */

.css-o0snlc,
mark {
  color: white !important;
  background-color: #2a3486 !important;
}

.chakra-stack.css-nq00bg {
  margin-top: 10px;
}
.chakra-stack.css-nq00bg span {
  margin: 2px 2px 5px 5px;
}

button.css-edc369 {
  position: relative;
  left: -4px;
  top: -2px;
}

@keyframes showNav {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes showNav2 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.board {
  transition: 0s all;
}

.opened {
  display: block;
  overflow: hidden;
  /* animation: showNav 0ms ease-in-out both; */
}

.closed {
  display: none;
  overflow: hidden;
  /* animation: showNav2 0ms ease-in-out both; */
}

section[role="alertdialog"] {
  background: #1f2225 !important;
  color: #ccc;
}

span.chakra-button__icon.css-1wh2kri {
}

.css-f5brtt-BaseToolTop {
  width: 300px;
}

p a {
  color: rgb(62, 185, 241);
}

.chakra-slide {
  background: rgb(26, 25, 34) !important;
  color: white;
}

.chakra-slide a {
  color: #ccc;
}
button.chakra-modal__close-btn.css-1iqbypn {
  color: white;
}

/* .custom-class {
  display: flex;
  justify-content: center;
  align-items: center;
} */
